<template>
  <n-grid cols="1 s:4" responsive="screen" style="padding-top: 30px">
    <n-gi span="2" offset="0 s:1">
      <n-card
        :title="
          t(`commons.actions.${model.id ? 'update' : 'create'}`, {
            reference: t('commons.labels.parameter', 1),
          })
        "
      >
        <n-form :model="model" ref="formRef" :rules="rules">
          <n-form-item :label="t('parameters.createUpdate.form.type.label')" path="type">
            <n-select
              v-model:value="model.type"
              :placeholder="t('parameters.createUpdate.form.type.placeholder')"
              :options="options"
              :disabled="model.id != null && model.id != undefined"
            />
          </n-form-item>
          <n-form-item :label="t('parameters.createUpdate.form.name.label')" path="name">
            <n-input
              v-model:value="model.name"
              :placeholder="t('parameters.createUpdate.form.name.placeholder')"
              :disabled="model.id != null && model.id != undefined"
            />
          </n-form-item>
          <n-form-item
            :label="t('parameters.createUpdate.form.value.label')"
            path="value"
            v-if="model.type"
          >
            <n-input
              v-if="model.type === 'STRING'"
              v-model:value="model.value"
              :placeholder="t('parameters.createUpdate.form.value.placeholder')"
            />
            <n-input-number
              v-if="model.type === 'NUMBER'"
              v-model:value="model.value"
              :placeholder="t('parameters.createUpdate.form.value.placeholder')"
            />
            <n-switch v-if="model.type === 'BOOLEAN'" v-model:value="model.value" />
          </n-form-item>

          <n-space justify="center">
            <n-button @click="cancelClick">
              {{ t("commons.actions.cancel") }}
            </n-button>
            <n-button @click="handleValidateClick">
              {{ model.id ? t("commons.actions.update") : t("commons.actions.save") }}
            </n-button>
          </n-space>
        </n-form>
      </n-card>
    </n-gi>
  </n-grid>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import {
  useMessage,
  NGrid,
  NGi,
  NCard,
  NForm,
  NFormItem,
  NSelect,
  NInput,
  NInputNumber,
  NSwitch,
  NSpace,
  NButton,
} from "naive-ui";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { constantCase } from "constant-case";
import { useI18n } from "vue-i18n";

export default {
  props: {
    id: String,
  },
  components: {
    NGrid,
    NGi,
    NCard,
    NForm,
    NFormItem,
    NSelect,
    NInput,
    NInputNumber,
    NSwitch,
    NSpace,
    NButton,
  },
  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const formRef = ref(null);
    const modelRef = ref({
      name: null,
      value: null,
      type: null,
    });
    const message = useMessage();

    let reload = false;

    const update = async () => {
      modelRef.value.name = constantCase(modelRef.value.name);
      if (modelRef.value.id) {
        return store.dispatch("maintainer_parameters/updateParameter", modelRef.value);
      } else {
        store.dispatch("maintainer_parameters/createParameter", modelRef.value);
      }
    };

    const loadData = (id) => {
      store.dispatch("maintainer_parameters/getParameterById", id).then((selected) => {
        modelRef.value = (({ id, name, type, value, _version }) => ({
          id,
          name,
          type,
          value,
          _version,
        }))(selected);
        if (modelRef.value.type === "BOOLEAN") {
          modelRef.value.value = modelRef.value.value === "true";
        }
        if (modelRef.value.type === "NUMBER") {
          modelRef.value.value = Number(modelRef.value.value);
        }
      });
    };

    onMounted(() => {
      if (props.id) {
        loadData(props.id);
      }
    });
    watch(
      () => route.params.categoryId,
      async (newId) => {
        if (newId && reload) {
          loadData(newId);
        }
      }
    );

    return {
      props,
      t,
      formRef,
      model: modelRef,
      rules: {
        name: {
          required: true,
          trigger: ["input", "blur"],
          validator(rule, value) {
            if (!value || value === undefined) {
              return new Error(t("commons.form.validation.required"));
            }
            if (
              !modelRef.value.id &&
              store.state.app.configuration[constantCase(value)]
            ) {
              return new Error(t("commons.form.validation.alreadyExists"));
            }
            return true;
          },
        },
        type: {
          required: true,
          message: t("commons.form.validation.required"),
          trigger: ["change", "blur"],
        },
        value: {
          required: true,
          trigger: ["input", "blur"],
          validator(rule, value) {
            switch (modelRef.value.type) {
              case "STRING":
                if (Object.prototype.toString.call(value) != "[object String]") {
                  return new Error(
                    t("commons.form.validation.valueMustBeThis", {
                      reference: t(`commons.inputTypes.${modelRef.value.type}`),
                    })
                  );
                }
                return true;
              case "NUMBER":
                if (Object.prototype.toString.call(value) != "[object Number]") {
                  return new Error(
                    t("commons.form.validation.valueMustBeThis", {
                      reference: t(`commons.inputTypes.${modelRef.value.type}`),
                    })
                  );
                }
                return true;
              case "BOOLEAN":
                if (Object.prototype.toString.call(value) != "[object Boolean]") {
                  return new Error(
                    t("commons.form.validation.valueMustBeThis", {
                      reference: t(`commons.inputTypes.${modelRef.value.type}`),
                    })
                  );
                }
                return true;
            }
            return true;
          },
        },
      },
      options: computed(() => store.getters["shared/options_inputType"]),
      handleValidateClick(e) {
        e.preventDefault();
        formRef.value.validate((errors) => {
          if (!errors) {
            update()
              .then(() => {
                message.success(
                  t("commons.actions.saved", {
                    reference: t("commons.labels.parameter", 1),
                  })
                );
                router.push({
                  name: "parameters",
                });
              })
              .catch((error) => {
                console.error(error);
                message.error(error);
              });
          }
        });
      },
      cancelClick(e) {
        e.preventDefault();
        router.push({
          name: "parameters",
        });
      },
    };
  },
};
</script>
